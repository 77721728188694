<script>
import _ from 'lodash'
import pdfMake from 'pdfmake'
import { Decimal } from 'decimal.js'
import numeral from 'numeral'

import pdfFonts from '@/assets/fonts/custom-fonts.js'
import { numberMixins } from '@/mixins'
import * as datetimeUtils from '@/utils/datetime'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

export default {
  mixins: [numberMixins],
  props: {
    reportDataProp: Object,
    generateReportArgsProp: Object,
    isPdfProcessingProp: Boolean
  },
  data() {
    return {
      contentConfig: {
        tableWidths: ['9%', '12.5%', '41%', '12.5%', '12.5%', '12.5%'],
        tableDetailWidths: [
          '9%',
          '12.5%',
          '20.5%',
          '20.5%',
          '12.5%',
          '12.5%',
          '12.5%'
        ],
        wordLimitLength: {
          firstListColumn: 23,
          secondListColumn: 23,
          default: 10
        }
      },
      docDefinition: {},
      listColumnSelectedFields: []
    }
  },
  watch: {
    isPdfProcessingProp() {
      if (this.isPdfProcessingProp && !_.isEmpty(this.reportDataProp)) {
        this.startGenerateReport()
      }
    }
  },
  methods: {
    getThaiDatePhrase(time) {
      return datetimeUtils.getThaiDatePhrase(time)
    },
    startGenerateReport() {
      // setup pdf config
      this.setupDocDefinitionConfig()

      // declare variables
      const maximumContentAmountPerPage = 27

      let currentPage = (() => {
        const initialPageNumber = this.generateReportArgsProp.initialPageNumber
        if (!_.isNil(initialPageNumber)) {
          return Number(initialPageNumber)
        }
        return 1
      })()

      this.listColumnSelectedFields = (() => {
        const defaultListColumnSelectedFields = ['referenceNumber', 'reference']
        const inputSelectedFields =
          this.reportDataProp.args.listColumnSelectedFields
        if (!inputSelectedFields || inputSelectedFields.length === 0) {
          return defaultListColumnSelectedFields
        }
        return inputSelectedFields
      })()

      /** separateAccountPerPage = true */
      if (this.generateReportArgsProp.separateAccountPerPage) {
        const reportDataPropDetails = _.cloneDeep(this.reportDataProp.details)
        if (reportDataPropDetails.length === 0) {
          /** no details */
          // header
          this.writeHeader(currentPage)
          currentPage += 1
        } else {
          /** have details */
          reportDataPropDetails.forEach((detail, detailIndex) => {
            const dataChunks = _.chunk(
              detail.journalDetailsData,
              maximumContentAmountPerPage
            )

            if (dataChunks.length === 0) {
              const isReportLastPage =
                detailIndex === this.reportDataProp.details.length - 1

              // header
              this.writeHeader(currentPage)
              currentPage += 1

              // account detail
              this.writeAccountDetail(detail.accountDetail)

              // previous balance
              this.writePreviousBalance(detail.previousBalanceData)

              // total per page
              this.writeTotalPerPage(
                detail.journalDetailsData,
                isReportLastPage
              )

              // cumulative all total
              this.writeCumulativeAllTotal([], !isReportLastPage)
            } else {
              let isLastDataChunkIndex = null
              let isFirstDataChunkIndex = null
              let isReportLastPage = null
              let cumulativeData = []

              dataChunks.forEach((dataChunk, dataChunkIndex) => {
                isLastDataChunkIndex = dataChunkIndex === dataChunks.length - 1
                isFirstDataChunkIndex = dataChunkIndex === 0
                isReportLastPage =
                  detailIndex === this.reportDataProp.details.length - 1

                // header
                this.writeHeader(currentPage)
                currentPage += 1

                // account detail
                this.writeAccountDetail(detail.accountDetail)

                // previous balance
                if (isFirstDataChunkIndex) {
                  this.writePreviousBalance(detail.previousBalanceData)
                }

                // journal details
                for (let journalDetailData of dataChunk) {
                  cumulativeData.push(journalDetailData)
                  this.writeJournalDetail(journalDetailData)
                }

                // total per page
                this.writeTotalPerPage(dataChunk)

                // cumulative all total
                this.writeCumulativeAllTotal(cumulativeData, true)
              })

              // header
              this.writeHeader(currentPage)
              currentPage += 1

              // account detail
              this.writeAccountDetail(detail.accountDetail)

              // new version of summary part
              this.writeSummaryPart(
                detail.previousBalanceData,
                detail.journalBookCodeSummary,
                detail.allTotal,
                !isReportLastPage
              )

              /* old version of summary part
                // // previousBalance
                // this.writePreviousBalance(detail.previousBalanceData)

                // // all total
                // this.writeAllTotal(detail.allTotal, !isLastDataChunkIndex)

                // // journal book code summary
                // if (isLastDataChunkIndex) {
                //   this.writeJournalBookCodeSummary(
                //     detail.journalBookCodeSummary,
                //     !isReportLastPage
                //   )
                // }
              */
            }
          })
        }
      } else {
        /** separateAccountPerPage = false */
        let currentLineIndex = 1

        const checkHeader = (should) => {
          if (should) {
            this.writeHeader(currentPage)
            currentPage += 1
          }
        }
        const currentLineIndexIncrement = () => {
          currentLineIndex += 1
        }
        const shouldAddHeader = () =>
          currentLineIndex % maximumContentAmountPerPage === 1
        const shouldAddPageBreak = () =>
          currentLineIndex % maximumContentAmountPerPage === 0

        this.reportDataProp.details.forEach((detail) => {
          // header
          checkHeader(shouldAddHeader())

          // account detail
          this.writeAccountDetail(detail.accountDetail, shouldAddPageBreak())
          currentLineIndexIncrement()

          // header
          checkHeader(shouldAddHeader())

          // previousBalance
          this.writePreviousBalance(
            detail.previousBalanceData,
            shouldAddPageBreak()
          )
          currentLineIndexIncrement()

          for (let journalDetail of detail.journalDetailsData) {
            // header
            checkHeader(shouldAddHeader())

            // journal detail
            this.writeJournalDetail(journalDetail, shouldAddPageBreak())
            currentLineIndexIncrement()
          }

          // header
          checkHeader(shouldAddHeader())

          // totalPerPage
          this.writeTotalPerPage(
            detail.journalDetailsData,
            shouldAddPageBreak()
          )
          currentLineIndexIncrement()

          // header
          checkHeader(shouldAddHeader())

          // allTotal
          this.writeAllTotal(detail.allTotal, shouldAddPageBreak())
          currentLineIndexIncrement()
        })

        // header
        checkHeader(shouldAddHeader())

        // journalBookCodeSummary
        this.writeJournalBookCodeSummary(this.reportDataProp.footer)
      }

      // generate pdf
      pdfMake.createPdf(this.docDefinition).open()

      // send finish event
      this.$emit('finishGenerateReport')
    },
    setupDocDefinitionConfig() {
      this.docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [25, 25],
        info: {
          title: 'รายงานสรุปผลบัญชีแยกประเภท',
          author: 'happysoft acccounting co., ltd'
        },
        content: [],
        styles: {
          alignmentLeft: {
            alignment: 'left'
          },
          alignmentCenter: {
            alignment: 'center'
          },
          alignmentRight: {
            alignment: 'right'
          }
        },
        defaultStyle: {
          font: 'THSarabunNew',
          fontSize: 12
        }
      }
    },
    writeHeader(pageNumber) {
      const header = _.cloneDeep(this.reportDataProp.header)
      const { companyName, dateTimeTh, fromDateTh, toDateTh } = header
      const splittedDateTimeTh = dateTimeTh.split(' ')
      const dateTh = splittedDateTimeTh[0]
      const timeTh = splittedDateTimeTh[1] + ' น.'

      const companySection = {
        columns: [
          {
            width: '100%',
            text: companyName,
            style: ['alignmentCenter'],
            bold: true,
            fontSize: 15,
            margin: [0, 0, 0, 5]
          }
        ]
      }

      const dateTypePageSection = {
        columns: [
          {
            width: '20%',
            text: dateTh,
            style: ['alignmentLeft']
          },
          {
            width: '60%',
            text: 'ส รุ ป ผ ล บั ญ ชี แ ย ก ป ร ะ เ ภ ท',
            style: ['alignmentCenter']
          },
          {
            width: '20%',
            text: `หน้า ${pageNumber}`,
            style: ['alignmentRight']
          }
        ]
      }

      const timePeriodFormSection = {
        columns: [
          {
            width: '20%',
            text: timeTh,
            style: ['alignmentLeft'],
            margin: [0, 0, 0, 10]
          },
          {
            width: '60%',
            text: `สำหรับรอบวันที่ ${this.getThaiDatePhrase(
              fromDateTh
            )} ถึง ${this.getThaiDatePhrase(toDateTh)}`,
            style: ['alignmentCenter'],
            margin: [0, 0, 0, 10]
          },
          {
            width: '20%',
            text: '',
            style: ['alignmentRight'],
            margin: [0, 0, 0, 10]
          }
        ]
      }

      const tableHeaderSection = {
        table: {
          widths: this.contentConfig.tableWidths,
          body: [
            [
              {
                border: [false, true, false, true],
                text: 'วันที่'
              },
              {
                border: [false, true, false, true],
                text: 'เอกสาร',
                alignment: 'left'
              },
              {
                border: [false, true, false, true],
                text: 'รายการ',
                alignment: 'center'
              },
              {
                border: [false, true, false, true],
                text: 'เดบิต',
                alignment: 'right'
              },
              {
                border: [false, true, false, true],
                text: `เครดิต`,
                alignment: 'right'
              },
              {
                border: [false, true, false, true],
                text: `คงเหลือ`,
                alignment: 'right'
              }
            ]
          ]
        }
      }

      this.docDefinition.content.push(
        companySection,
        dateTypePageSection,
        timePeriodFormSection,
        tableHeaderSection
      )
    },
    writeAccountDetail(accountDetail, pageBreak = false) {
      const {
        glmaccn_id: id,
        glmaccn_accountName: name,
        glmaccn_accountNumber: number,
        glmaccn_consoldateAccount: consolidateAccount
      } = accountDetail

      const accountDetailSection = {
        table: {
          widths: this.contentConfig.tableWidths,
          body: [
            [
              {
                border: [false, false, false, false],
                margin: this.generateReportArgsProp.separateAccountPerPage
                  ? null
                  : [0, 20, 0, 0],
                text: number ?? '-'
              },
              {
                border: [false, false, false, false],
                margin: this.generateReportArgsProp.separateAccountPerPage
                  ? null
                  : [0, 20, 0, 0],
                text: consolidateAccount ?? '-'
              },
              {
                border: [false, false, false, false],
                margin: this.generateReportArgsProp.separateAccountPerPage
                  ? null
                  : [0, 20, 0, 0],
                text: name ?? '-'
              }
            ]
          ]
        }
      }

      if (pageBreak) {
        accountDetailSection.pageBreak = 'after'
      }

      this.docDefinition.content.push(accountDetailSection)
    },
    writePreviousBalance(previousBalanceData, pageBreak = false) {
      const { debit, credit, total } = previousBalanceData
      const negativeValueParenthesis =
        this.generateReportArgsProp.displayNegativeValueInParenthesis

      const debitBalance = _.isNull(debit)
        ? null
        : this.mxNumeralThousandSeperate(debit)
      const creditBalance = _.isNull(credit)
        ? null
        : this.mxNumeralThousandSeperate(credit)
      const totalBalance = _.isNull(total)
        ? null
        : this.mxNumeralThousandSeperate(total)

      const previousBalanceSection = {
        table: {
          widths: this.contentConfig.tableWidths,
          body: [
            [
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: 'ยอดยกมา'
              },
              {
                border: [false, false, false, false],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(debitBalance)
                  : debitBalance,
                alignment: 'right'
              },
              {
                border: [false, false, false, false],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(creditBalance)
                  : creditBalance,
                alignment: 'right'
              },
              {
                border: [false, false, false, false],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(totalBalance)
                  : totalBalance,
                alignment: 'right'
              }
            ]
          ]
        }
      }

      if (pageBreak) {
        previousBalanceSection.pageBreak = 'after'
      }

      this.docDefinition.content.push(previousBalanceSection)
    },
    writeJournalDetail(journalDetailData, pageBreak = false) {
      const {
        credit,
        dateFormatTh,
        debit,
        journalBookCode,
        journalDocumentNumber,
        referenceDocument,
        referenceNote,
        remark,
        total,
        journalHeader
      } = journalDetailData
      const negativeValueParenthesis =
        this.generateReportArgsProp.displayNegativeValueInParenthesis

      const debitBalanceHandler = _.isNull(debit)
        ? null
        : this.mxNumeralThousandSeperate(debit)
      const creditBalanceHandler = _.isNull(credit)
        ? null
        : this.mxNumeralThousandSeperate(credit)
      const totalBalanceHandler = _.isNull(total)
        ? null
        : this.mxNumeralThousandSeperate(total)

      const journalDetailSection = {
        table: {
          widths: this.contentConfig.tableDetailWidths,
          body: [
            [
              {
                border: [false, false, false, false],
                text: dateFormatTh
              },
              {
                border: [false, false, false, false],
                text: `${journalBookCode ?? ''} ${journalDocumentNumber ?? ''}`
              },
              {
                border: [false, false, false, false],
                text: this.sliceStringLength(
                  'firstListColumn',
                  this.getTextByField(
                    journalDetailData,
                    this.listColumnSelectedFields[0]
                  ) ?? ''
                )
              },
              {
                border: [false, false, false, false],
                text: this.sliceStringLength(
                  'secondListColumn',
                  this.getTextByField(
                    journalDetailData,
                    this.listColumnSelectedFields[1]
                  ) ?? ''
                ),
                alignment: 'left'
              },
              {
                border: [false, false, false, false],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(debitBalanceHandler)
                  : debitBalanceHandler,
                alignment: 'right'
              },
              {
                border: [false, false, false, false],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(creditBalanceHandler)
                  : creditBalanceHandler,
                alignment: 'right'
              },
              {
                border: [false, false, false, false],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(totalBalanceHandler)
                  : totalBalanceHandler,
                alignment: 'right'
              }
            ]
          ]
        }
      }

      if (pageBreak) {
        journalDetailSection.pageBreak = 'after'
      }

      this.docDefinition.content.push(journalDetailSection)
    },
    writeTotalPerPage(dataChunk, pageBreak = false) {
      const negativeValueParenthesis =
        this.generateReportArgsProp.displayNegativeValueInParenthesis

      const allTotalData = {
        debit: new Decimal(0),
        credit: new Decimal(0)
      }

      for (let d of dataChunk) {
        const { debit, credit } = d
        allTotalData.debit = allTotalData.debit.plus(Number(debit))
        allTotalData.credit = allTotalData.credit.plus(Number(credit))
      }

      allTotalData.total = allTotalData.debit
        .minus(allTotalData.credit)
        .toFixed(2)
      allTotalData.debit = allTotalData.debit.toFixed(2)
      allTotalData.credit = allTotalData.credit.toFixed(2)

      const { credit, debit, total } = allTotalData

      const debitHandler = this.mxNumeralThousandSeperate(debit)
      const creditHandler = this.mxNumeralThousandSeperate(credit)
      const totalHandler = this.mxNumeralThousandSeperate(total)

      const isSeparateAccountPerPage =
        this.generateReportArgsProp.separateAccountPerPage
      const borderConfig = isSeparateAccountPerPage
        ? [false, true, false, false]
        : [false, true, false, false]

      const allTotalSection = {
        table: {
          widths: this.contentConfig.tableDetailWidths,
          body: [
            [
              {
                border: borderConfig,
                margin: [0, 3, 0, 0],
                text: 'รวม / หน้า'
              },
              {
                border: borderConfig,
                margin: [0, 3, 0, 0],
                text: ''
              },
              {
                border: borderConfig,
                margin: [0, 3, 0, 0],
                text: ''
              },
              {
                border: borderConfig,
                margin: [0, 3, 0, 0],
                text: isSeparateAccountPerPage ? '' : 'ยอดยกมา',
                alignment: 'right'
              },
              {
                border: borderConfig,
                margin: [0, 3, 0, 0],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(debitHandler)
                  : debitHandler,
                alignment: 'right'
              },
              {
                border: borderConfig,
                margin: [0, 3, 0, 0],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(creditHandler)
                  : creditHandler,
                alignment: 'right'
              },
              {
                border: borderConfig,
                margin: [0, 3, 0, 0],
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(totalHandler)
                  : totalHandler,
                alignment: 'right'
              }
            ]
          ]
        }
      }

      if (pageBreak) {
        allTotalSection.pageBreak = 'after'
      }

      this.docDefinition.content.push(allTotalSection)
    },
    writeAllTotal(allTotalData, pageBreak = false) {
      const { credit, debit, previousBalance, total } = allTotalData
      const negativeValueParenthesis =
        this.generateReportArgsProp.displayNegativeValueInParenthesis

      const creditHandler = this.mxNumeralThousandSeperate(credit)
      const debitHandler = this.mxNumeralThousandSeperate(debit)
      const totalHandler = this.mxNumeralThousandSeperate(total)
      const previousBalanceHandler =
        this.mxNumeralThousandSeperate(previousBalance)

      const isSeparateAccountPerPage =
        this.generateReportArgsProp.separateAccountPerPage
      const borderConfig = isSeparateAccountPerPage
        ? [false, true, false, true]
        : [false, false, false, true]

      const allTotalSection = {
        table: {
          widths: this.contentConfig.tableDetailWidths,
          body: [
            [
              {
                border: borderConfig,
                text: 'รวมทั้งหมด'
              },
              {
                border: borderConfig,
                text: ''
              },
              {
                border: borderConfig,
                text: ''
              },
              {
                border: borderConfig,
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(
                      previousBalanceHandler
                    )
                  : previousBalanceHandler,
                alignment: 'right'
              },
              {
                border: borderConfig,
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(debitHandler)
                  : debitHandler,
                alignment: 'right'
              },
              {
                border: borderConfig,
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(creditHandler)
                  : creditHandler,
                alignment: 'right'
              },
              {
                border: borderConfig,
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(totalHandler)
                  : totalHandler,
                alignment: 'right'
              }
            ]
          ]
        }
      }

      if (pageBreak) {
        allTotalSection.pageBreak = 'after'
      }

      this.docDefinition.content.push(allTotalSection)
    },
    writeCumulativeAllTotal(data, pageBreak = false) {
      const negativeValueParenthesis =
        this.generateReportArgsProp.displayNegativeValueInParenthesis
      const borderConfig = [false, false, false, true]

      let totalDebit = new Decimal(0.0)
      let totalCredit = new Decimal(0.0)
      let totalResult = new Decimal(0.0)

      for (let d of data) {
        const { debit, credit } = d
        if (debit !== null) totalDebit = totalDebit.plus(debit)
        if (credit !== null) totalCredit = totalCredit.plus(credit)
      }

      const finalTotalDebit = this.mxNumeralThousandSeperate(
        totalDebit.toFixed(2)
      )
      const finalTotalCredit = this.mxNumeralThousandSeperate(
        totalCredit.toFixed(2)
      )
      const finalTotalResult = this.mxNumeralThousandSeperate(
        totalResult.plus(totalDebit).minus(totalCredit).toFixed(2)
      )

      const cumulativeAllTotalSection = {
        table: {
          widths: this.contentConfig.tableDetailWidths,
          body: [
            [
              {
                border: borderConfig,
                text: 'รวมทั้งหมด'
              },
              {
                border: borderConfig,
                text: ''
              },
              {
                border: borderConfig,
                text: ''
              },
              {
                border: borderConfig,
                text: ''
              },
              {
                border: borderConfig,
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(finalTotalDebit)
                  : finalTotalDebit,
                alignment: 'right'
              },
              {
                border: borderConfig,
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(finalTotalCredit)
                  : finalTotalCredit,
                alignment: 'right'
              },
              {
                border: borderConfig,
                text: negativeValueParenthesis
                  ? this.wrapNegativeValueWithParenthesis(finalTotalResult)
                  : finalTotalResult,
                alignment: 'right'
              }
            ]
          ]
        }
      }

      if (pageBreak) {
        cumulativeAllTotalSection.pageBreak = 'after'
      }

      this.docDefinition.content.push(cumulativeAllTotalSection)
    },
    writeJournalBookCodeSummary(journalBookCodeSummaryData, pageBreak = false) {
      const negativeValueParenthesis =
        this.generateReportArgsProp.displayNegativeValueInParenthesis
      const journalBookCodes = _.keys(journalBookCodeSummaryData)
      const journalBookCodeSummarySection = journalBookCodes.map(
        (journalBookCode, index) => {
          const { debit, credit } = journalBookCodeSummaryData[journalBookCode]
          const debitHandler = this.mxNumeralThousandSeperate(debit)
          const creditHandler = this.mxNumeralThousandSeperate(credit)
          return {
            table: {
              widths: this.contentConfig.tableDetailWidths,
              body: [
                [
                  {
                    border: [false, false, false, false],
                    margin: index === 0 ? [0, 5, 0, 0] : null,
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    margin: index === 0 ? [0, 5, 0, 0] : null,
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    margin: index === 0 ? [0, 5, 0, 0] : null,
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    margin: index === 0 ? [0, 5, 0, 0] : null,
                    text: journalBookCode,
                    alignment: 'center'
                  },
                  {
                    border: [false, false, false, false],
                    margin: index === 0 ? [0, 5, 0, 0] : null,
                    text: negativeValueParenthesis
                      ? this.wrapNegativeValueWithParenthesis(debitHandler)
                      : debitHandler,
                    alignment: 'right'
                  },
                  {
                    border: [false, false, false, false],
                    margin: index === 0 ? [0, 5, 0, 0] : null,
                    text: negativeValueParenthesis
                      ? this.wrapNegativeValueWithParenthesis(creditHandler)
                      : creditHandler,
                    alignment: 'right'
                  },
                  {
                    border: [false, false, false, false],
                    margin: index === 0 ? [0, 5, 0, 0] : null,
                    text: ''
                  }
                ]
              ]
            }
          }
        }
      )
      if (pageBreak) {
        const lastIndex = journalBookCodeSummarySection.length - 1
        journalBookCodeSummarySection[lastIndex].pageBreak = 'after'
      }
      this.docDefinition.content.push(journalBookCodeSummarySection)
    },
    sliceStringLength(key, value) {
      const wordLimitLengthObj = this.contentConfig.wordLimitLength

      if (_.keys(wordLimitLengthObj).includes(key)) {
        const maxLimitLength = wordLimitLengthObj[key]
        if (value.trim().length > maxLimitLength) {
          return value.slice(0, maxLimitLength)
        }
        return value
      }

      const defaultMaxLimitLength = wordLimitLengthObj['default']
      if (value.trim().length > defaultMaxLimitLength) {
        return value.slice(0, defaultMaxLimitLength)
      }
      return value
    },
    wrapNegativeValueWithParenthesis(numberInStringFormat) {
      if (!_.isString(numberInStringFormat)) {
        return numberInStringFormat
      }

      const n = Number(numberInStringFormat.replace(/,/g, ''))

      if (n < 0) {
        return `(${numeral(n * -1).format('0,0.00')})`
      }

      return numberInStringFormat
    },
    getTextByField(args, field) {
      let fieldMapping = null
      switch (field) {
        case 'referenceNumber': {
          fieldMapping = 'journalHeader.gltjnth_journalNumber'
          break
        }
        case 'branch': {
          fieldMapping = 'branch.name'
          break
        }
        case 'description': {
          fieldMapping = 'remark'
          break
        }
        case 'reference': {
          fieldMapping = 'journalHeader.gltjnth_description'
          break
        }
        case 'remark': {
          fieldMapping = 'journalHeader.gltjnth_remark'
          break
        }
        default:
          break
      }

      if (!fieldMapping) {
        return null
      }
      const value = _.get(args, fieldMapping)
      if (_.isNil(value)) {
        return null
      } else if (_.isString(value)) {
        return value.trim().length === 0 ? null : value.trim()
      } else {
        return `${value}`
      }
    },
    writeSummaryPart(
      previousBalanceData,
      journalBookCodeSummaryData,
      allTotalData,
      pageBreak
    ) {
      const fixedMargin = [0, 5, 0, 5]

      const previousBalanceValue = Number(previousBalanceData.total)
      const previousBalanceSection = {
        table: {
          widths: this.contentConfig.tableDetailWidths,
          body: [
            [
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: 'ยอดยกมา',
                alignment: 'right'
              },
              {
                border: [false, false, false, true],
                margin: fixedMargin,
                text:
                  previousBalanceValue >= 0
                    ? this.mxNumeralThousandSeperate(previousBalanceValue)
                    : '',
                alignment: 'right'
              },
              {
                border: [false, false, false, true],
                margin: fixedMargin,
                text:
                  previousBalanceValue < 0
                    ? this.mxNumeralThousandSeperate(previousBalanceValue * -1)
                    : '',
                alignment: 'right'
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              }
            ]
          ]
        }
      }

      const journalBookCodeSummaryRows = Object.keys(
        journalBookCodeSummaryData
      ).map((journalBookCode) => {
        return [
          {
            border: [false, false, false, false],
            margin: fixedMargin,
            text: ''
          },
          {
            border: [false, false, false, false],
            margin: fixedMargin,
            text: ''
          },
          {
            border: [false, false, false, false],
            margin: fixedMargin,
            text: ''
          },
          {
            border: [false, false, false, false],
            margin: fixedMargin,
            text: journalBookCode,
            alignment: 'right'
          },
          {
            border: [false, false, false, false],
            margin: fixedMargin,
            text: this.mxNumeralThousandSeperate(
              journalBookCodeSummaryData[journalBookCode].debit
            ),
            alignment: 'right'
          },
          {
            border: [false, false, false, false],
            margin: fixedMargin,
            text: this.mxNumeralThousandSeperate(
              journalBookCodeSummaryData[journalBookCode].credit
            ),
            alignment: 'right'
          },
          {
            border: [false, false, false, false],
            margin: fixedMargin,
            text: ''
          }
        ]
      })
      const journalBookCodeSummarySection = {
        table: {
          widths: this.contentConfig.tableDetailWidths,
          body: journalBookCodeSummaryRows
        }
      }

      const allTotalBalance = Number(allTotalData.total)
      const allTotalSection = {
        table: {
          widths: this.contentConfig.tableDetailWidths,
          body: [
            [
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: 'คงเหลือ',
                alignment: 'right'
              },
              {
                border: [false, true, false, false],
                margin: fixedMargin,
                text:
                  allTotalBalance >= 0
                    ? this.mxNumeralThousandSeperate(allTotalBalance)
                    : '',
                alignment: 'right'
              },
              {
                border: [false, true, false, false],
                margin: fixedMargin,
                text:
                  allTotalBalance < 0
                    ? this.mxNumeralThousandSeperate(allTotalBalance * -1)
                    : '',
                alignment: 'right'
              },
              {
                border: [false, false, false, false],
                margin: fixedMargin,
                text: ''
              }
            ]
          ]
        }
      }

      if (pageBreak) {
        allTotalSection.pageBreak = 'after'
      }

      this.docDefinition.content.push(
        previousBalanceSection,
        journalBookCodeSummarySection,
        allTotalSection
      )
    }
  }
}
</script>

<template></template>

<style></style>
