export const getThaiDatePhrase = (time) => {
  if (!time) return

  // time: string | null
  // expect time to be in this format: 01/01/2566

  const [day, month, year] = time.split('/')
  const thaiMonthPhrase = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ]
  return `${Number(day)} ${thaiMonthPhrase[Number(month) - 1]} ${year}`
}
