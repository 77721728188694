<script>
export default {
  props: {
    titleProp: String,
    sortBySelectedProp: String,
    isGeneratingReportProp: Boolean
  },
  data() {
    return {
      sortBySelected: null,
      sortByOptions: [
        { text: 'วันที่สร้าง', value: 'createdAt' },
        { text: 'วันที่เอกสาร', value: 'transactionDate' },
        { text: 'เลขที่เอกสาร', value: 'journalNumber' },
        { text: 'เอกสารอ้างอิง', value: 'referenceDocument' },
        { text: 'คำอธิบายอ้างอิง', value: 'referenceNote' },
        { text: 'หมายเหตุ', value: 'remark' },
        { text: '- ว่าง -', value: null }
      ]
    }
  },
  watch: {
    sortBySelected() {
      this.$emit('sortBySelectedChanged', this.sortBySelected)
    },
    sortBySelectedProp() {
      this.checkSortBySelected()
    }
  },
  created() {
    this.checkSortBySelected()
  },
  methods: {
    checkSortBySelected() {
      if (this.sortBySelected !== this.sortBySelectedProp) {
        this.sortBySelected = this.sortBySelectedProp
      }
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="4" class="d-flex align-items-center">
      <div>
        <span>{{ titleProp || 'เรียงตาม' }}</span>
      </div>
    </b-col>
    <b-col cols="8">
      <div>
        <b-form-select
          v-model="sortBySelected"
          :options="sortByOptions"
          :disabled="isGeneratingReportProp"
        ></b-form-select>
      </div>
    </b-col>
  </b-row>
</template>

<style></style>
