<script>
import _ from 'lodash'

export default {
  props: {
    titleProp: String,
    selectedProp: Boolean,
    isGeneratingReportProp: Boolean
  },
  data() {
    return {
      selected: false
    }
  },
  watch: {
    selected() {
      this.$emit('selectedChanged', this.selected)
    },
    selectedProp() {
      this.checkSelectedValue()
    }
  },
  created() {
    this.checkSelectedValue()
  },
  methods: {
    checkSelectedValue() {
      if (!_.isEqual(this.selected, this.selectedProp)) {
        this.selected = this.selectedProp
      }
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="5" class="d-flex align-items-center">
      <div>
        <span>
          {{ titleProp || 'เลือกหรือไม่เลือก' }}
        </span>
      </div>
    </b-col>
    <b-col cols="7" class="d-flex align-items-center">
      <b-form-checkbox v-model="selected" :disabled="isGeneratingReportProp">
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<style></style>
