<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import AccountSelection from './report-setting-components/AccountSelection.vue'
import BooleanInput from './report-setting-components/BooleanInput.vue'
import DateSelection from './report-setting-components/DateSelection.vue'
import InitialPageNumberInput from './report-setting-components/InitialPageNumberInput.vue'
import ListColumnFieldSelection from './report-setting-components/ListColumnFieldSelection.vue'
import SortBySelection from './report-setting-components/SortBySelection.vue'

export default {
  components: {
    AccountSelection,
    BooleanInput,
    DateSelection,
    InitialPageNumberInput,
    ListColumnFieldSelection,
    SortBySelection
  },
  props: {
    reportDataProp: Object,
    isGeneratingReportProp: Boolean
  },
  data() {
    return {
      sortByFirstSelected: 'transactionDate',
      sortBySecondSelected: 'journalNumber',
      sortByThirdSelected: null,
      fromDate: {
        day: null,
        month: null,
        year: null
      },
      toDate: {
        day: null,
        month: null,
        year: null
      },
      requestType: 'interval',
      fromAccount: null,
      toAccount: null,
      accountNumbers: null,
      initialPageNumber: '1',
      separateAccountPerPage: true,
      displayUnavailableAccount: false,
      displayNegativeValueInParenthesis: false,
      reportSettingDisplayModal: true,
      listColumnSelectedFields: ['referenceNumber', 'reference'],
      saveReportSetting: true,
      errMsg: null,
      reportData: {}
    }
  },
  computed: {
    ...mapState({
      stateAuthCompany: (state) => state.auth.company
    })
  },
  watch: {
    reportDataProp() {
      const excludeFields = ['accountActivityReportFormId']
      const concernDateFields = ['fromDate', 'toDate']
      const concernListColumnFields = ['listColumnSelectedFields']
      Object.keys(this.reportDataProp).forEach((key) => {
        const value = this.reportDataProp[key]

        if (excludeFields.includes(key)) {
          return
        }

        if (concernDateFields.includes(key)) {
          const splittedDate = value.split('-')
          const year = (Number(splittedDate[0]) + 543).toString()
          const month = `${Number(splittedDate[1])}`.padStart(2, '0')
          const day = `${Number(splittedDate[2])}`.padStart(2, '0')
          const expectFormat = { day, month, year }
          if (key === 'fromDate') {
            this.fromDate = expectFormat
          } else if (key === 'toDate') {
            this.toDate = expectFormat
          }
          return
        }

        if (concernListColumnFields.includes(key)) {
          if (value) {
            this.listColumnSelectedFields = value
          }
          return
        }

        switch (key) {
          case 'accountNumbers':
            this.accountNumbers = value
            break
          case 'displayNegativeValueInParenthesis':
            this.displayNegativeValueInParenthesis = value
            break
          case 'displayUnavailableAccount':
            this.displayUnavailableAccount = value
            break
          case 'fromAccount':
            this.fromAccount = value
            break
          case 'initialPageNumber':
            this.initialPageNumber = `${value}`
            break
          case 'requestType':
            this.requestType = value
            break
          case 'separateAccountPerPage':
            this.separateAccountPerPage = value
            break
          case 'sortByFirstSelected':
            this.sortByFirstSelected = value
            break
          case 'sortBySecondSelected':
            this.sortBySecondSelected = value
            break
          case 'sortByThirdSelected':
            this.sortByThirdSelected = value
            break
          case 'toAccount':
            this.toAccount = value
            break
          default:
            break
        }
      })
      this.saveReportSetting = false
    }
  },
  created() {
    this.initializeDate()
  },
  methods: {
    initializeDate() {
      if (this.stateAuthCompany) {
        const { programStartDate } = this.stateAuthCompany
        const [year, month, date] = programStartDate.split('-')
        const buddhistYear = `${Number(year) + 543}`
        this.fromDate = { day: date, month, year: buddhistYear }
        this.toDate = { day: '31', month, year: buddhistYear }
      }
    },
    sortBySelectedChangedHandler(type, value) {
      switch (type) {
        case 'FIRST': {
          this.sortByFirstSelected = value
          break
        }
        case 'SECOND': {
          this.sortBySecondSelected = value
          break
        }
        case 'THIRD': {
          this.sortByThirdSelected = value
          break
        }
        default: {
          break
        }
      }
    },
    dateSelectedChangedHandler(type, value) {
      switch (type) {
        case 'FROM_DATE': {
          this.fromDate = _.cloneDeep(value)
          break
        }
        case 'TO_DATE': {
          this.toDate = _.cloneDeep(value)
          break
        }
        default: {
          break
        }
      }
    },
    requestTypeChangedHandler(value) {
      this.requestType = value
    },
    accountChangedHandler(payload) {
      const { type, value } = payload
      switch (type) {
        case 'FROM': {
          this.fromAccount = value
          break
        }
        case 'TO': {
          this.toAccount = value
          break
        }
        default: {
          break
        }
      }
    },
    accountsChangedHandler(value) {
      this.accountNumbers = value
    },
    initialPageNumberChangedHandler(value) {
      this.initialPageNumber = value
    },
    selectedChangedHandler(type, value) {
      switch (type) {
        case 'SEPARATE_PAGE': {
          this.separateAccountPerPage = value
          break
        }
        case 'UNAVAILABLE_ACCOUNT': {
          this.displayUnavailableAccount = value
          break
        }
        case 'NEGATIVE_VALUE': {
          this.displayNegativeValueInParenthesis = value
          break
        }
        case 'SAVE_REPORT_SETTING': {
          this.saveReportSetting = value
          break
        }
        default: {
          break
        }
      }
    },
    selectedFieldsChangedHandler(newSelectedFields) {
      this.listColumnSelectedFields = [...newSelectedFields]
    },
    getGenerateReportArgs() {
      const args = {}

      // accountActivityReportFormId: number
      // TODO: change this when report form module is implemented
      args.accountActivityReportFormId = 1

      // sortBy: GenerateAccountActivitySortByEnum | null
      const sortByKeyValues = [
        { key: 'sortByFirst', value: this.sortByFirstSelected },
        { key: 'sortBySecond', value: this.sortBySecondSelected },
        { key: 'sortByThird', value: this.sortByThirdSelected }
      ]
      sortByKeyValues.forEach((el) => {
        const { key, value } = el
        args[key] = value
      })

      // requestType: GenerateAccountActivityRequestTypeEnum
      args.requestType = this.requestType

      switch (this.requestType) {
        case 'interval': {
          // fromAccount: string | null
          const fromAccountValue = this.fromAccount
          args.fromAccount =
            _.isString(fromAccountValue) && fromAccountValue.trim().length > 0
              ? fromAccountValue.trim()
              : null

          // toAccount: string | null
          const toAccountValue = this.toAccount
          args.toAccount =
            _.isString(toAccountValue) && toAccountValue.trim().length > 0
              ? toAccountValue.trim()
              : null

          args.accountNumbers = null
          break
        }
        case 'specific': {
          args.fromAccount = null
          args.toAccount = null

          // acountNumbers: string[] | null
          args.accountNumbers = this.accountNumbers
          break
        }
      }

      // fromDate: string
      const fdObj = { ...this.fromDate }
      const fromDateFormat = `${Number(fdObj.year) - 543}-${fdObj.month}-${
        fdObj.day
      }`
      args.fromDate = fromDateFormat

      // toDate: string
      const tdObj = { ...this.toDate }
      const toDateFormat = `${Number(tdObj.year) - 543}-${tdObj.month}-${
        tdObj.day
      }`
      args.toDate = toDateFormat

      // initialPageNumber: number
      args.initialPageNumber = Number(this.initialPageNumber)

      // separateAccountPerPage: boolean
      args.separateAccountPerPage = this.separateAccountPerPage

      // displayUnavailableAccount: boolean
      args.displayUnavailableAccount = this.displayUnavailableAccount

      // displayNegativeValueInParenthesis: boolean
      args.displayNegativeValueInParenthesis =
        this.displayNegativeValueInParenthesis

      // listColumnSelectedFields: string[] | null
      const listColumnSelectedFields = _.chain(this.listColumnSelectedFields)
        .filter((el) => Boolean(el))
        .value()
      args.listColumnSelectedFields =
        listColumnSelectedFields.length > 0 ? listColumnSelectedFields : null

      // saveReportSetting: boolean
      args.saveReportSetting = this.saveReportSetting

      return args
    },
    async generateReportButtonClicked() {
      const generateReportArgs = this.getGenerateReportArgs()
      await this.$emit('startGenerateReport', generateReportArgs)
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12" class="bg-light p-4 border-radius-10px">
      <!-- topic -->
      <b-row class="mb-3">
        <b-col>
          <span class="text-lg font-weight-bold">ตั้งค่าออกรายงาน</span>
        </b-col>
      </b-row>

      <!-- content -->
      <b-row>
        <!-- left section -->
        <b-col cols="12" lg="6">
          <b-row>
            <!-- sortByFirst -->
            <b-col cols="12" class="mb-2">
              <SortBySelection
                :titleProp="'อันดับ(แรก)เรียงตาม'"
                :sortBySelectedProp="sortByFirstSelected"
                :isGeneratingReportProp="isGeneratingReportProp"
                @sortBySelectedChanged="
                  sortBySelectedChangedHandler('FIRST', $event)
                "
              ></SortBySelection>
            </b-col>

            <!-- sortBySecond -->
            <b-col cols="12" class="mb-2">
              <SortBySelection
                :titleProp="'อันดับ(สอง)เรียงตาม'"
                :sortBySelectedProp="sortBySecondSelected"
                :isGeneratingReportProp="isGeneratingReportProp"
                @sortBySelectedChanged="
                  sortBySelectedChangedHandler('SECOND', $event)
                "
              ></SortBySelection>
            </b-col>

            <!-- sortByThird -->
            <b-col cols="12" class="mb-2">
              <SortBySelection
                :titleProp="'อันดับ(สาม)เรียงตาม'"
                :sortBySelectedProp="sortByThirdSelected"
                :isGeneratingReportProp="isGeneratingReportProp"
                @sortBySelectedChanged="
                  sortBySelectedChangedHandler('THIRD', $event)
                "
              ></SortBySelection>
            </b-col>

            <!-- fromDate -->
            <b-col cols="12" class="mb-2">
              <DateSelection
                :titleProp="'ในช่วงวันที่ จาก'"
                :dateSelectedProp="fromDate"
                :isGeneratingReportProp="isGeneratingReportProp"
                @dateSelectedChanged="
                  dateSelectedChangedHandler('FROM_DATE', $event)
                "
              />
            </b-col>

            <!-- toDate -->
            <b-col cols="12" class="mb-2">
              <DateSelection
                :titleProp="'ในช่วงวันที่ ถึง'"
                :dateSelectedProp="toDate"
                :isGeneratingReportProp="isGeneratingReportProp"
                @dateSelectedChanged="
                  dateSelectedChangedHandler('TO_DATE', $event)
                "
              />
            </b-col>

            <!-- account selection (interval / specific) -->
            <b-col cols="12" class="mb-3">
              <AccountSelection
                :requestTypeProp="requestType"
                :fromAccountProp="fromAccount"
                :toAccountProp="toAccount"
                :accountNumbersProp="accountNumbers"
                :isGeneratingReportProp="isGeneratingReportProp"
                @requestTypeChanged="requestTypeChangedHandler"
                @accountChanged="accountChangedHandler"
                @accountsChanged="accountsChangedHandler"
              ></AccountSelection>
            </b-col>
          </b-row>
        </b-col>

        <!-- right section -->
        <b-col cols="12" lg="6">
          <b-row>
            <!-- initialPageNumber -->
            <b-col cols="12" class="mb-2">
              <InitialPageNumberInput
                :initialPageNumberProp="initialPageNumber"
                :isGeneratingReportProp="isGeneratingReportProp"
                @initialPageNumberChanged="initialPageNumberChangedHandler"
              ></InitialPageNumberInput>
            </b-col>

            <!-- separateAccountPerPage -->
            <b-col cols="12" class="mb-2">
              <BooleanInput
                :titleProp="'บัญชีใหม่ขึ้นหน้าใหม่'"
                :selectedProp="separateAccountPerPage"
                :isGeneratingReportProp="isGeneratingReportProp"
                @selectedChanged="
                  selectedChangedHandler('SEPARATE_PAGE', $event)
                "
              ></BooleanInput>
            </b-col>

            <!-- displayUnavailableAccount -->
            <b-col cols="12" class="mb-2">
              <BooleanInput
                :titleProp="'แสดงบัญชีไม่เคลื่อนไหว'"
                :selectedProp="displayUnavailableAccount"
                :isGeneratingReportProp="isGeneratingReportProp"
                @selectedChanged="
                  selectedChangedHandler('UNAVAILABLE_ACCOUNT', $event)
                "
              ></BooleanInput>
            </b-col>

            <!-- displayNegativeValueInParenthesis -->
            <b-col cols="12" class="mb-2">
              <BooleanInput
                :titleProp="'แสดงค่าลบใน(วงเล็บ)'"
                :selectedProp="displayNegativeValueInParenthesis"
                :isGeneratingReportProp="isGeneratingReportProp"
                @selectedChanged="
                  selectedChangedHandler('NEGATIVE_VALUE', $event)
                "
              ></BooleanInput>
            </b-col>

            <!-- listColumnSelectedFields -->
            <b-col cols="12" class="mb-2">
              <ListColumnFieldSelection
                :titleProp="'ช่องรายการ'"
                :selectedFieldsProp="listColumnSelectedFields"
                :isGeneratingReportProp="isGeneratingReportProp"
                @selectedFieldsChanged="selectedFieldsChangedHandler"
              ></ListColumnFieldSelection>
            </b-col>

            <!-- saveReportSetting -->
            <b-col cols="12" class="my-2 text-sm">
              <BooleanInput
                :titleProp="'บันทึกการตั้งค่าออกรายงานนี้'"
                :selectedProp="saveReportSetting"
                :isGeneratingReportProp="isGeneratingReportProp"
                @selectedChanged="
                  selectedChangedHandler('SAVE_REPORT_SETTING', $event)
                "
              ></BooleanInput>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- hover line -->
      <hr />

      <!-- buttons -->
      <b-row>
        <b-col class="m-0 p-0">
          <div class="d-flex justify-content-start justify-content-lg-end">
            <b-button
              variant="primary"
              class="mx-3"
              @click="generateReportButtonClicked()"
              :disabled="
                isGeneratingReportProp ||
                (requestType === 'specific' && !accountNumbers)
              "
            >
              <b-spinner
                v-if="isGeneratingReportProp"
                variant="light"
                small
              ></b-spinner>
              <span v-else>ออกรายงาน</span>
            </b-button>
            <b-button
              v-if="false"
              variant="outline-primary"
              class="mx-3"
              @click="reportSettingDisplayModal = true"
              :disabled="isGeneratingReportProp"
            >
              ตั้งค่าการแสดงผล
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<style scoped>
.border-radius-10px {
  border-radius: 10px;
}
</style>
