<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import reportClassifyService from '@/services/report/classify'

import MenuBar from './components/MenuBar.vue'
import ReportSetting from './components/ReportSetting.vue'
import GeneratePdfByPdfMake from './components/GeneratePdfByPdfMake.vue'
import ReportHistory from './components/ReportHistory.vue'

export default {
  components: { MenuBar, ReportSetting, GeneratePdfByPdfMake, ReportHistory },
  data() {
    return {
      shouldUpdateReportHistory: false,
      isGeneratingReport: false,
      isPdfProcessing: false,
      reportData: {},
      generateReportArgs: {},
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    })
  },
  methods: {
    async startGenerateReportHandler(args) {
      this.isGeneratingReport = true
      try {
        const { postGenerateAccountActivity } = reportClassifyService
        this.generateReportArgs = _.cloneDeep(args)
        const result = await postGenerateAccountActivity(args)
        this.reportData = _.cloneDeep(result.data.data)
        setTimeout(() => {
          this.isPdfProcessing = true
        }, 500)
      } catch (err) {
        this.errMsg =
          err.response?.data?.thMessage ||
          'ดึงข้อมูลในการออกรายงานสรุปผลบัญชีแยกประเภทไม่สำเร็จ'
        this.isGeneratingReport = false
      }
    },
    finishGenerateReportHandler() {
      this.isGeneratingReport = false
      this.isPdfProcessing = false
      this.shouldUpdateReportHistory = true
    },
    updateReportHistorySuccessfulHandler() {
      this.shouldUpdateReportHistory = false
    },
    applyReportDataHandler(appliedReportData) {
      this.reportData = _.cloneDeep(appliedReportData)
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- page title / menu bar -->
    <b-row class="d-flex justify-content-between">
      <!-- page title -->
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <span class="text-xxxl text-white page-title-style">
          ออกรายงานสรุปผลบัญชีแยกประเภท
        </span>
      </b-col>
      <!-- menu bar -->
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- report setting -->
    <b-row v-if="user && user.permission.reportAccountActivity.canPrint">
      <b-col cols="12">
        <report-setting
          class="mt-3"
          :reportDataProp="reportData"
          :isGeneratingReportProp="isGeneratingReport"
          @startGenerateReport="startGenerateReportHandler"
        ></report-setting>
      </b-col>
    </b-row>

    <!-- report history -->
    <b-row>
      <b-col cols="12">
        <ReportHistory
          class="mt-4"
          :isPdfProcessingProp="isPdfProcessing || isGeneratingReport"
          :shouldUpdateReportHistoryProp="shouldUpdateReportHistory"
          @startGenerateReport="startGenerateReportHandler"
          @updateReportHistorySuccessful="updateReportHistorySuccessfulHandler"
          @applyReportData="applyReportDataHandler"
        ></ReportHistory>
      </b-col>
    </b-row>

    <!-- generate pdf by pdf make -->
    <div>
      <GeneratePdfByPdfMake
        :reportDataProp="reportData"
        :generateReportArgsProp="generateReportArgs"
        :isPdfProcessingProp="isPdfProcessing"
        @finishGenerateReport="finishGenerateReportHandler"
      ></GeneratePdfByPdfMake>
    </div>

    <!-- error modal -->
    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style scoped>
.page-title-style {
  text-shadow: 2px 2px grey;
}
</style>
