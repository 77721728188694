<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import { datetimeMixins, toastMixins } from '@/mixins'
import service from '@/services/report/classify.js'

export default {
  mixins: [datetimeMixins, toastMixins],
  props: {
    shouldUpdateReportHistoryProp: Boolean,
    isPdfProcessingProp: Boolean
  },
  data() {
    return {
      fields: [
        { key: 'no', label: 'ลำดับที่' },
        { key: 'requestType', label: 'ประเภท' },
        { key: 'fromAccount', label: 'พิมพ์จาก' },
        { key: 'toAccount', label: 'พิมพ์ถึง' },
        { key: 'accountNumbersCount', label: 'จำนวนรหัส' },
        { key: 'sortByFirst', label: 'เรียงตาม#1' },
        { key: 'sortBySecond', label: 'เรียงตาม#2' },
        { key: 'sortByThird', label: 'เรียงตาม#3' },
        { key: 'fromDate', label: 'จากวันที่' },
        { key: 'toDate', label: 'ถึงวันที่' },
        { key: 'initialPageNumber', label: 'เริ่มต้นจากหน้า' },
        { key: 'separateAccountPerPage', label: 'บ/ช ใหม่ขึ้นหน้าใหม่' },
        { key: 'displayUnavailableAccount', label: 'บ/ช ไม่เคลื่อนไหว' },
        { key: 'displayNegativeValueInParenthesis', label: 'ค่าลบในเว็บเล็บ' },
        { key: 'firstListColumnSelectedField', label: 'รายการ#1' },
        { key: 'secondListColumnSelectedField', label: 'รายการ#2' },
        { key: 'remark', label: 'หมายเหตุ' },
        { key: 'createdAt', label: 'สร้างเมื่อ' },
        { key: 'createdBy', label: 'สร้างโดย' },
        { key: 'downloadUseDelete', label: '' }
      ],
      originalReportHistory: [],
      reportHistory: [],
      isLoading: false,
      accountActivityData: {},
      errMsg: null,
      omitFields: ['createdAt', 'createdBy', 'id', 'updatedAt', 'updatedBy']
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    })
  },
  watch: {
    shouldUpdateReportHistoryProp() {
      if (this.shouldUpdateReportHistoryProp) {
        this.fetchReportHistories()
        this.$emit('updateReportHistorySuccessful')
      }
    }
  },
  created() {
    this.fetchReportHistories()
  },
  methods: {
    async fetchReportHistories() {
      try {
        const res = await service.getAccountActivityHistories()
        this.originalReportHistory = _.cloneDeep(res.data.data)
        this.reportHistory = res.data.data.map((el, index) =>
          this.reformatFields(el, index + 1)
        )
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ดึงข้อมูลประวัติการออกรายงานสรุปผลบัญชีแยกประเภทไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    reformatFields(report, index) {
      const {
        id,
        requestType,
        fromAccount,
        toAccount,
        accountNumbers,
        sortByFirst,
        sortBySecond,
        sortByThird,
        fromDate,
        toDate,
        initialPageNumber,
        separateAccountPerPage,
        displayUnavailableAccount,
        displayNegativeValueInParenthesis,
        listColumnSelectedFields,
        remark,
        createdAt,
        createdBy
      } = report

      return {
        no: index,
        historyId: id,
        requestType,
        fromAccount,
        toAccount,
        accountNumbers,
        accountNumbersCount: accountNumbers ? accountNumbers.length : 0,
        sortByFirst,
        sortBySecond,
        sortByThird,
        fromDate,
        toDate,
        initialPageNumber,
        separateAccountPerPage,
        displayUnavailableAccount,
        displayNegativeValueInParenthesis,
        listColumnSelectedFields,
        firstListColumnSelectedField: listColumnSelectedFields
          ? listColumnSelectedFields[0]
          : null,
        secondListColumnSelectedField: listColumnSelectedFields
          ? listColumnSelectedFields[1]
          : null,
        remark,
        createdAt,
        createdBy
      }
    },
    downloadButtonClicked(historyId) {
      const el = this.originalReportHistory.find(
        (report) => report.id === historyId
      )
      this.accountActivityData = _.omit(
        {
          ..._.cloneDeep(el),
          saveReportSetting: false
        },
        this.omitFields
      )
      this.$emit('startGenerateReport', this.accountActivityData)
    },
    applyButtonClicked(historyId) {
      const element = this.originalReportHistory.find(
        (report) => report.id === historyId
      )
      const keyValueMapping = {
        accountActivityReportFormId: 'accountActivityReportFormId',
        accountNumbers: 'accountNumbers',
        displayNegativeValueInParenthesis: 'displayNegativeValueInParenthesis',
        displayUnavailableAccount: 'displayUnavailableAccount',
        fromAccount: 'fromAccount',
        fromDate: 'fromDate',
        initialPageNumber: 'initialPageNumber',
        listColumnSelectedFields: 'listColumnSelectedFields',
        requestType: 'requestType',
        separateAccountPerPage: 'separateAccountPerPage',
        sortByFirst: 'sortByFirstSelected',
        sortBySecond: 'sortBySecondSelected',
        sortByThird: 'sortByThirdSelected',
        toAccount: 'toAccount',
        toDate: 'toDate'
      }
      const reformatElement = {}
      Object.keys(keyValueMapping).forEach((sourceKey) => {
        const targetKey = keyValueMapping[sourceKey]
        const value = element[sourceKey]
        reformatElement[targetKey] = value
      })
      this.$emit('applyReportData', reformatElement)
    },
    async deleteButtonClicked(historyId) {
      this.isLoading = true
      try {
        await service.deleteAccountActivityHistoryById(historyId)
        await this.fetchReportHistories()
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ลบประวัติการออกรายงานสรุปผลบัญชีแยกประเภทไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    },
    mapSortByPhrase(inputKey) {
      const keyValueMappings = [
        { text: 'วันที่สร้าง', value: 'createdAt' },
        { text: 'วันที่เอกสาร', value: 'transactionDate' },
        { text: 'เลขที่เอกสาร', value: 'journalNumber' },
        { text: 'เอกสารอ้างอิง', value: 'referenceDocument' },
        { text: 'คำอธิบายอ้างอิง', value: 'referenceNote' },
        { text: 'หมายเหตุ', value: 'remark' }
      ]
      const definedKeys = keyValueMappings.map((x) => x.value)
      if (!definedKeys.includes(inputKey)) return null
      return keyValueMappings.find((x) => x.value === inputKey).text
    },
    mapListColumnPhrase(inputKey) {
      const keyValues = [
        { value: 'referenceNumber', text: 'เลขที่อ้างอิง' },
        { value: 'reference', text: 'อ้างอิง' },
        { value: 'branch', text: 'สาขา' },
        { value: 'remark', text: 'หมายเหตุ' },
        { value: 'description', text: 'คำอธิบาย' }
      ]
      const definedKeys = keyValues.map((x) => x.value)
      if (!definedKeys.includes(inputKey)) return null
      return keyValues.find((x) => x.value === inputKey).text
    },
    formatToBuddhistDate(inputDate) {
      const expectFormat = 'XXXX-XX-XX'

      const isCorrectType = typeof inputDate === 'string'
      if (!isCorrectType) return null

      const isCorrectLength = expectFormat.length
      if (!isCorrectLength) return null

      const isCorrectDashPositions = [inputDate[4], inputDate[7]].every(
        (el) => el === '-'
      )
      if (!isCorrectDashPositions) return null

      const isCorrectDashAmount =
        inputDate.split('').reduce((count, el) => {
          if (el === '-') {
            count += 1
          }
          return count
        }, 0) === 2
      if (!isCorrectDashAmount) return null

      const [year, month, date] = inputDate.split('-')
      const yearNumber = Number(year)
      const monthNumber = Number(month)
      const dateNumber = Number(date)

      const isCorrectYearMonthDate = (() => {
        return (
          1 <= yearNumber &&
          yearNumber <= 9999 &&
          1 <= monthNumber &&
          monthNumber <= 12 &&
          1 <= dateNumber &&
          dateNumber <= 31
        )
      })()
      if (!isCorrectYearMonthDate) return null

      const dateTh = dateNumber.toString().padStart(2, '0')
      const monthTh = monthNumber.toString().padStart(2, '0')
      const yearTh = (yearNumber + 543).toString().padStart(2, '0')
      return `${dateTh}/${monthTh}/${yearTh}`
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px">
      <div>
        <!-- topic -->
        <div>
          <span class="text-lg font-weight-bold">ประวัติการออกรายงาน</span>
        </div>

        <!-- table -->
        <div class="mt-3 overflow-auto">
          <b-table
            striped
            hover
            bordered
            small
            :fields="fields"
            :items="reportHistory"
            :head-variant="'dark'"
            :sticky-header="true"
            class="text-sm"
          >
            <!-- table header -->
            <template #head(no)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(requestType)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(fromAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(toAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(accountNumbersCount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(sortByFirst)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(sortBySecond)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(sortByThird)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(fromDate)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(toDate)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(initialPageNumber)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(separateAccountPerPage)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(displayUnavailableAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(displayNegativeValueInParenthesis)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(firstListColumnSelectedField)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(secondListColumnSelectedField)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(remark)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(createdAt)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(createdBy)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(downloadUseDelete)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <!-- table body -->
            <template #cell(no)="data">
              {{ data.item.no }}
            </template>
            <template #cell(requestType)="data">
              {{
                (() => {
                  switch (data.item.requestType.toLowerCase()) {
                    case 'interval': {
                      return 'ช่วง'
                    }
                    case 'specific': {
                      return 'เจาะจง'
                    }
                    default: {
                      return null
                    }
                  }
                })() || '-'
              }}
            </template>
            <template #cell(fromAccount)="data">
              {{ data.item.fromAccount || '-' }}
            </template>
            <template #cell(toAccount)="data">
              {{ data.item.toAccount || '-' }}
            </template>
            <template #cell(accountNumbersCount)="data">
              {{ data.item.accountNumbersCount || '-' }}
            </template>
            <template #cell(sortByFirst)="data">
              {{ mapSortByPhrase(data.item.sortByFirst) || '-' }}
            </template>
            <template #cell(sortBySecond)="data">
              {{ mapSortByPhrase(data.item.sortBySecond) || '-' }}
            </template>
            <template #cell(sortByThird)="data">
              {{ mapSortByPhrase(data.item.sortByThird) || '-' }}
            </template>
            <template #cell(fromDate)="data">
              {{ formatToBuddhistDate(data.item.fromDate) || '-' }}
            </template>
            <template #cell(toDate)="data">
              {{ formatToBuddhistDate(data.item.toDate) || '-' }}
            </template>
            <template #cell(initialPageNumber)="data">
              {{ data.item.initialPageNumber || '-' }}
            </template>
            <template #cell(separateAccountPerPage)="data">
              {{ data.item.separateAccountPerPage ? 'ใช่' : '-' }}
            </template>
            <template #cell(displayUnavailableAccount)="data">
              {{ data.item.displayUnavailableAccount ? 'ใช่' : '-' }}
            </template>
            <template #cell(displayNegativeValueInParenthesis)="data">
              {{ data.item.displayNegativeValueInParenthesis ? 'ใช่' : '-' }}
            </template>
            <template #cell(firstListColumnSelectedField)="data">
              {{
                mapListColumnPhrase(data.item.firstListColumnSelectedField) ||
                '-'
              }}
            </template>
            <template #cell(secondListColumnSelectedField)="data">
              {{
                mapListColumnPhrase(data.item.secondListColumnSelectedField) ||
                '-'
              }}
            </template>
            <template #cell(remark)="data">
              {{ data.item.remark || '-' }}
            </template>
            <template #cell(createdAt)="data">
              {{ mxConvertToDatetimeBuddhistFormat(data.item.createdAt) }}
            </template>
            <template #cell(downloadUseDelete)="data">
              <div class="d-flex justify-content-center">
                <div class="mr-2">
                  <b-button
                    v-if="
                      user && user.permission.reportAccountActivity.canPrint
                    "
                    variant="success"
                    size="sm"
                    @click="downloadButtonClicked(data.item.historyId)"
                    :disabled="isLoading || isPdfProcessingProp"
                  >
                    <b-icon icon="download" scale="0.8"></b-icon>
                  </b-button>
                </div>
                <div class="mr-2">
                  <b-button
                    variant="primary"
                    size="sm"
                    v-if="
                      user && user.permission.reportAccountActivity.canPrint
                    "
                    :disabled="isLoading || isPdfProcessingProp"
                  >
                    <b-icon
                      icon="box-arrow-up"
                      scale="0.8"
                      @click="applyButtonClicked(data.item.historyId)"
                    ></b-icon>
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-if="
                      user && user.permission.reportAccountActivity.canDelete
                    "
                    variant="danger"
                    size="sm"
                    @click="deleteButtonClicked(data.item.historyId)"
                    :disabled="isLoading || isPdfProcessingProp"
                  >
                    <b-icon icon="trash" scale="0.8"></b-icon>
                  </b-button>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-col>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-row>
</template>

<style scoped>
.header-column-style {
  text-align: center;
}
</style>
