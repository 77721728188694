<script>
import _ from 'lodash'

export default {
  props: {
    titleProp: String,
    selectedFieldsProp: Array,
    isGeneratingReportProp: Boolean
  },
  data() {
    return {
      fields: [
        { value: 'referenceNumber', text: 'เลขที่อ้างอิง' },
        { value: 'reference', text: 'อ้างอิง' },
        { value: 'branch', text: 'สาขา' },
        { value: 'remark', text: 'หมายเหตุ' },
        { value: 'description', text: 'คำอธิบาย' }
      ],
      firstField: null,
      secondField: null,
      selectedFields: []
    }
  },
  watch: {
    selectedFieldsProp() {
      this.checkListColumnSelectedFields()
    },
    selectedFields() {
      this.$emit('selectedFieldsChanged', this.selectedFields)
      const [x, y] = this.selectedFields
      this.firstField = x
      this.secondField = y
    },
    firstField() {
      switch (this.selectedFields.length) {
        case 0: {
          this.selectedFields = [this.firstField]
          break
        }
        case 1:
        case 2: {
          this.selectedFields = [this.firstField, this.secondField]
          break
        }
        default: {
          break
        }
      }
    },
    secondField() {
      if (this.secondField === null) {
        return
      }

      switch (this.selectedFields.length) {
        case 0: {
          this.secondField = null
          break
        }
        case 1:
        case 2: {
          this.selectedFields = [this.firstField, this.secondField]
          break
        }
        default: {
          break
        }
      }
    }
  },
  created() {
    this.checkListColumnSelectedFields()
  },
  methods: {
    checkListColumnSelectedFields() {
      const incomingFields = _.cloneDeep(this.selectedFieldsProp)
      const currentFields = _.cloneDeep(this.selectedFields)
      if (!this.checkFieldsEquality(incomingFields, currentFields)) {
        this.selectedFields = [...this.selectedFieldsProp]
      }
    },
    resetSelectedFieldStatus() {
      this.selectedFields = []
    },
    checkFieldsEquality(incomingFields, currentFields) {
      return _.isEqual(incomingFields, currentFields)
    }
  }
}
</script>

<template>
  <b-row>
    <!-- 1 -->
    <b-col cols="5" class="d-flex flex-column justify-content-center mb-2">
      <div>
        <span> {{ titleProp || 'เลือกหรือไม่เลือก' }} #1 </span>
      </div>
      <div class="text-black-50 text-sm">
        <span> ช่องที่ 1 (ซ้าย) </span>
      </div>
    </b-col>
    <b-col cols="7" class="d-flex flex-column justify-content-between mb-2">
      <b-form-select
        v-model="firstField"
        :options="fields"
        :disabled="isGeneratingReportProp"
      ></b-form-select>
    </b-col>

    <!-- 2 -->
    <b-col cols="5" class="d-flex flex-column justify-content-center">
      <div>
        <span> {{ titleProp || 'เลือกหรือไม่เลือก' }} #2 </span>
      </div>
      <div class="text-black-50 text-sm">
        <span> ช่องที่ 2 (ขวา) </span>
      </div>
    </b-col>
    <b-col cols="7" class="d-flex flex-column justify-content-between">
      <b-form-select
        v-model="secondField"
        :options="fields"
        :disabled="isGeneratingReportProp || selectedFields.length < 1"
      ></b-form-select>
    </b-col>
  </b-row>
</template>

<style></style>
