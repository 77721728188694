<template>
  <b-modal
    v-model="displayModal"
    size="xl"
    scrollable
    title="เลือกเฉพาะเลขที่บัญชี"
    header-bg-variant="primary"
    header-text-variant="light"
  >
    <b-row>
      <b-col
        cols="12"
        lg="6"
        class="mb-2 mb-lg-0 d-flex align-items-center justify-content-center justify-content-lg-start"
      >
        <div>
          <b-button
            variant="primary"
            size="sm"
            class="mr-2"
            @click="selectAllButtonClicked()"
          >
            เลือกทั้งหมด
          </b-button>
          <b-button
            variant="outline-primary"
            size="sm"
            class="mr-2"
            @click="deselectAllButtonClicked()"
          >
            ยกเลิกทั้งหมด
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="selectOppositeButtonClicked()"
          >
            สลับการเลือก
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" lg="6">
        <div
          class="mb-2 mb-lg-0 d-flex align-items-center justify-content-center justify-content-lg-end"
        >
          <b-select
            class="mr-3"
            style="width: 40%;"
            size="sm"
            :options="sortTypeOptions"
            v-model="sortType"
          ></b-select>
          <b-input
            style="width: 40%;"
            placeholder="ค้นหา เลขที่บัญชี"
            size="sm"
            v-model="searchKeyword"
          ></b-input>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4">
        <b-table
          hover
          outlined
          bordered
          sticky-header="430px"
          :fields="tableHeaders"
          :items="displayAccounts"
          thead-tr-class="text-center text-nowrap"
          tbody-tr-class="text-nowrap"
          class="border"
          @row-clicked="rowClickedHandler"
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner
                class="align-middle mr-2"
                variant="primary"
              ></b-spinner>
              <strong>กำลังโหลดข้อมูล ...</strong>
            </div>
          </template>
          <template #cell(selected)="data">
            <div class="d-flex justify-content-center align-items-center">
              <div
                v-if="
                  selectedAccounts
                    .map(acc => acc.accountNumber)
                    .includes(data.item.accountNumber)
                "
                class="d-flex justify-content-center align-items-center"
                style="width: 20px; height: 20px; border-radius: 100%;"
              >
                <b-icon icon="check-circle-fill" variant="primary"></b-icon>
              </div>
              <div
                v-else
                style="width: 20px; height: 20px; border-radius: 100%;"
              ></div>
            </div>
          </template>
          <template #cell(accountNumber)="data">
            {{ data.item.accountNumber }}
          </template>
          <template #cell(accountName)="data">
            {{ data.item.accountName }}
          </template>
          <template #cell(accountTypeMessage)="data">
            {{ data.item.accountTypeMessage }}
          </template>
          <template #cell(parentAccount)="data">
            {{ data.item.parentAccount }}
          </template>
          <template #cell(rcsiMessage)="data">
            {{ data.item.rcsiMessage }}
          </template>
          <template #cell(accountLevel)="data">
            {{ data.item.accountLevel }}
          </template>
          <template #cell(groupMessage)="data">
            {{ data.item.groupMessage }}
          </template>
          <template #cell(consolidateAccount)="data">
            {{ data.item.consolidateAccount }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="d-flex">
        <div class="mr-3">
          <b-button variant="primary" @click="enterButtonClicked()">
            ตกลง
          </b-button>
        </div>
        <div @click="displayModal = false">
          <b-button variant="outline-primary">
            ยกเลิก
          </b-button>
        </div>
      </div>
    </template>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-modal>
</template>

<script>
import _ from 'lodash'

import { toastMixins } from '@/mixins'
import ge1mastService from '@/services/master/ge1mast.js'

/*
Props: {
  accountSelectedIdsProp: accounts ที่ถูกเลือกก่อนหน้านี้
  displayModalProp: เปิดปิดการแสดง modal (type: boolean)
  summaryAccountAvailableProp: true = มีบัญชีสรุปยอดให้เลือก false = ไม่มีบัญชีสรุปยอดให้เลือก
}
Events: {
  modalClosed: เมื่อ modal ถูกปิด (payload: undefined)
  accountsSelected: เมื่อเลือกบัญชีได้แล้ว (payload: {
    id: number
    accountNumber: string
    accountName: string
    accountType: string
    accountTypeMessage: string 
    parentAccount: string | null
    rcsi: boolean
    rcsiMessage: string
    accountLevel: number
    group: string
    groupMessage: string 
    groupNumber: string
    noChilds: number
    consolidateAccount: string | null
    deletedAt: Datetime | null
  }[])
}
*/

export default {
  mixins: [toastMixins],
  props: {
    accountSelectedIdsProp: Array,
    displayModalProp: Boolean,
    summaryAccountAvailableProp: Boolean
  },
  data() {
    return {
      displayModal: false,
      summaryAccountAvailable: false,
      tableHeaders: [
        {
          key: 'selected',
          label: 'เลือก'
        },
        {
          key: 'accountNumber',
          label: 'เลขที่บัญชี'
        },
        { key: 'accountName', label: 'ชื่อบัญชี' },
        {
          key: 'accountTypeMessage',
          label: 'ชนิด'
        },
        {
          key: 'parentAccount',
          label: 'บัญชีแม่'
        },
        {
          key: 'rcsiMessage',
          label: 'เป็น'
        },
        { key: 'accountLevel', label: 'ระดับ' },
        { key: 'groupMessage', label: 'หมวด' },
        {
          key: 'consolidateAccount',
          label: 'บัญชีกลาง'
        }
      ],
      accounts: [],
      selectedAccounts: [],
      sortType: 'asc',
      sortTypeOptions: [
        { value: 'desc', text: 'ท้ายสุด - แรกสุด' },
        { value: 'asc', text: 'แรกสุด - ท้ายสุด' }
      ],
      searchKeyword: '',
      isLoading: false,
      errMsg: null
    }
  },
  computed: {
    displayAccounts() {
      return _.orderBy(
        this.accounts
          .map(acc => {
            const clonedAcc = { ...acc }

            if (!clonedAcc.parentAccount) clonedAcc.parentAccount = '-'
            if (!clonedAcc.rcsiMessage) clonedAcc.rcsiMessage = '-'
            if (!clonedAcc.consolidateAccount)
              clonedAcc.consolidateAccount = '-'

            return clonedAcc
          })
          .filter(acc => {
            const trimKeyword = this.searchKeyword

            return trimKeyword.trim().length > 0
              ? acc.accountNumber.startsWith(trimKeyword.trim())
              : true
          }),
        ['accountNumber'],
        [this.sortType.toLowerCase()]
      )
    }
  },
  watch: {
    accountSelectedIdsProp() {
      this.assignDataFromProp('accountSelectedIdsProp')
    },
    displayModalProp() {
      this.assignDataFromProp('displayModalProp')
    },
    summaryAccountAvailableProp() {
      this.assignDataFromProp('summaryAccountAvailableProp')
    },
    displayModal() {
      if (!this.displayModal) {
        this.$emit('modalClosed')
      }
    }
  },
  created() {
    this.assignDataFromProp('accountSelectedIdsProp')
    this.assignDataFromProp('displayModalProp')
    this.assignDataFromProp('summaryAccountAvailableProp')
  },
  methods: {
    async assignDataFromProp(prop) {
      switch (prop) {
        case 'displayModalProp':
          this.displayModal = this.displayModalProp
          if (this.displayModal) {
            this.fetchGeneralLedgerMasterAccounts()
          }
          break
        case 'accountSelectedIdsProp':
          if (
            this.accountSelectedIdsProp.length > 0 &&
            this.accounts.length === 0
          )
            await this.fetchGeneralLedgerMasterAccounts()

          this.selectedAccounts = this.accounts.filter(acc =>
            this.accountSelectedIdsProp.includes(acc.accountNumber)
          )
          break
        case 'summaryAccountAvailableProp':
          this.summaryAccountAvailable = this.summaryAccountAvailableProp
          break
        default:
          break
      }
    },
    async fetchGeneralLedgerMasterAccounts() {
      this.isLoading = true
      try {
        const qs = [`summary=${this.summaryAccountAvailable}`]
        const result = await ge1mastService.getAllGeneralLedgerMasterAccounts(
          qs
        )
        this.accounts = this.reformatAccounts(result.data.data)
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลผังบัญชีไม่สำเร็จ'
        this.errMsg = errMessage
      } finally {
        this.isLoading = false
      }
    },
    reformatAccounts(accounts) {
      const formatAccounts = []

      accounts.forEach(acc => {
        const formatAcc = {}

        for (let key of Object.keys(acc)) {
          const newKey = key.replace('glmaccn_', '')
          const value = acc[key]
          formatAcc[newKey] = value
        }

        formatAccounts.push(formatAcc)
      })

      return formatAccounts
    },
    enterButtonClicked() {
      this.displayModal = false

      this.$emit('accountsSelected', this.selectedAccounts)
    },
    selectAllButtonClicked() {
      this.selectedAccounts = [...this.accounts]
    },
    deselectAllButtonClicked() {
      this.selectedAccounts = []
    },
    selectOppositeButtonClicked() {
      const allAccounts = [...this.accounts]
      const selectedAccountNumbers = this.selectedAccounts.map(
        acc => acc.accountNumber
      )
      this.selectedAccounts = allAccounts.filter(
        acc => !selectedAccountNumbers.includes(acc.accountNumber)
      )
    },
    rowClickedHandler(account) {
      const selectedAccountNumbers = this.selectedAccounts.map(
        acc => acc.accountNumber
      )

      if (selectedAccountNumbers.includes(account.accountNumber)) {
        this.selectedAccounts = this.selectedAccounts.filter(
          acc => acc.accountNumber !== account.accountNumber
        )
      } else {
        this.selectedAccounts.push(account)
      }
    }
  }
}
</script>

<style scoped></style>
