import client from './../api.js'

const endpoint = '/report/classify'

export default {
  // Trial balance
  postGenerateTrialBalanceReport: async (body) => {
    return await client.post(`${endpoint}/trial-balance`, body)
  },
  getTrialBalanceReportHistories: async () => {
    return await client.get(`${endpoint}/trial-balance/histories`)
  },
  deleteTrialBalanceReportHistoryById: async (historyId) => {
    return await client.put(`${endpoint}/trial-balance/histories/${historyId}`)
  },
  // Profit and loss statement
  postGenerateProfitAndLossStatement: async (body) => {
    return await client.post(`${endpoint}/profit-and-loss-statement`, body)
  },
  getProfitAndLossStatementHistories: async () => {
    return await client.get(`${endpoint}/profit-and-loss-statement/histories`)
  },
  deleteProfitAndLossStatementHistoryById: async (historyId) => {
    return await client.put(
      `${endpoint}/profit-and-loss-statement/histories/${historyId}`
    )
  },
  // Balance sheet
  postGenerateBalanceSheet: async (body) => {
    return await client.post(`${endpoint}/balance-sheet`, body)
  },
  getBalanceSheetHistories: async () => {
    return await client.get(`${endpoint}/balance-sheet/histories`)
  },
  deleteBalanceSheetHistoryById: async (historyId) => {
    return await client.put(`${endpoint}/balance-sheet/histories/${historyId}`)
  },
  // Working paper
  postGenerateWorkingPaper: async (body) => {
    return await client.post(`${endpoint}/working-paper`, body)
  },
  getWorkingPaperHistories: async () => {
    return await client.get(`${endpoint}/working-paper/histories`)
  },
  deleteWorkingPaperHistoryById: async (historyId) => {
    return await client.put(`${endpoint}/working-paper/histories/${historyId}`)
  },
  // Account Activity
  postGenerateAccountActivity: (body) => {
    return client.post(`${endpoint}/account-activity`, body)
  },
  getAccountActivityHistories: () => {
    return client.get(`${endpoint}/account-activity/histories`)
  },
  deleteAccountActivityHistoryById: (historyId) => {
    return client.delete(`${endpoint}/account-activity/histories/${historyId}`)
  }
}
