<script>
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  props: {
    titleProp: String,
    dateSelectedProp: Object,
    isGeneratingReportProp: Boolean
  },
  data() {
    return {
      dateSelected: {
        day: null,
        month: null,
        year: null
      },
      dayOptions: [
        { text: '01', value: '01' },
        { text: '02', value: '02' },
        { text: '03', value: '03' },
        { text: '04', value: '04' },
        { text: '05', value: '05' },
        { text: '06', value: '06' },
        { text: '07', value: '07' },
        { text: '08', value: '08' },
        { text: '09', value: '09' },
        { text: '10', value: '10' },
        { text: '11', value: '11' },
        { text: '12', value: '12' },
        { text: '13', value: '13' },
        { text: '14', value: '14' },
        { text: '15', value: '15' },
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' },
        { text: '22', value: '22' },
        { text: '23', value: '23' },
        { text: '24', value: '24' },
        { text: '25', value: '25' },
        { text: '26', value: '68' },
        { text: '27', value: '27' },
        { text: '28', value: '28' },
        { text: '29', value: '29' },
        { text: '30', value: '30' },
        { text: '31', value: '31' }
      ],
      monthOptions: [
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' }
      ],
      yearOptions: []
    }
  },
  computed: {
    ...mapState({
      stateAuthCompany: (state) => state.auth.company
    }),
    displayAccountMonth() {
      const month = this.dateSelected.month
      const year = this.dateSelected.year

      if (month && year) {
        if (this.yearOptions[0].value === year) {
          return month
        } else {
          return (Number(month) + 12).toString()
        }
      }

      return '-'
    }
  },
  watch: {
    dateSelected: {
      deep: true,
      handler() {
        this.$emit('dateSelectedChanged', _.cloneDeep(this.dateSelected))
      }
    },
    dateSelectedProp: {
      deep: true,
      handler() {
        this.checkDateSelected()
      }
    }
  },
  created() {
    this.initializeYearOptions()
    this.checkDateSelected()
  },
  methods: {
    initializeYearOptions() {
      if (this.stateAuthCompany) {
        const { programStartDate, programEndDate } = this.stateAuthCompany
        this.yearOptions = [programStartDate, programEndDate].map((el) => {
          const [year] = el.split('-')
          const buddhistYear = `${Number(year) + 543}`
          return { text: buddhistYear, value: buddhistYear }
        })
      }
    },
    checkDateSelected() {
      if (!_.isEqual(this.dateSelected, this.dateSelectedProp)) {
        this.dateSelected = _.cloneDeep(this.dateSelectedProp)
      }
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="4" class="d-flex align-items-center">
      <div>
        <span>{{ titleProp || 'ในช่วงวันที่' }}</span>
      </div>
    </b-col>
    <b-col cols="6">
      <div class="d-flex">
        <b-form-select
          v-model="dateSelected.day"
          :options="dayOptions"
          :disabled="isGeneratingReportProp"
        ></b-form-select>
        <b-form-select
          v-model="dateSelected.month"
          :options="monthOptions"
          :disabled="isGeneratingReportProp"
        ></b-form-select>
        <b-form-select
          v-model="dateSelected.year"
          :options="yearOptions"
          :disabled="isGeneratingReportProp"
        ></b-form-select>
      </div>
    </b-col>
    <b-col cols="2" class="d-flex align-items-center">
      <div>
        <span>งวดที่ {{ displayAccountMonth }}</span>
      </div>
    </b-col>
  </b-row>
</template>

<style></style>
