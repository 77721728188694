<script>
import _ from 'lodash'

import SelectAccountModal from '@/components/modal/SelectAccountModal.vue'
import SelectAccountsModal from '@/components/modal/SelectAccountsModal.vue'

export default {
  components: {
    SelectAccountModal,
    SelectAccountsModal
  },
  props: {
    requestTypeProp: String,
    fromAccountProp: String,
    toAccountProp: String,
    accountNumbersProp: Array,
    isGeneratingReportProp: Boolean
  },
  data() {
    return {
      requestType: null,
      requestTypeOptions: [
        { text: 'ช่วง', value: 'interval' },
        { text: 'เลือก', value: 'specific' }
      ],
      fromAccount: null,
      toAccount: null,
      accountNumbers: null,
      displaySelectAccountModal: false,
      displaySelectAccountsModal: false,
      focusAttribute: null
    }
  },
  watch: {
    requestType() {
      this.$emit('requestTypeChanged', this.requestType)
    },
    requestTypeProp() {
      this.checkRequestType()
    },
    fromAccount() {
      const fromAccountHandler =
        _.isString(this.fromAccount) && this.fromAccount.trim().length > 0
          ? this.fromAccount.trim()
          : null
      const payload = { type: 'FROM', value: fromAccountHandler }
      this.$emit('accountChanged', payload)
    },
    fromAccountProp() {
      this.checkFromAccount()
    },
    toAccount() {
      const toAccountHandler =
        _.isString(this.toAccount) && this.toAccount.trim().length > 0
          ? this.toAccount.trim()
          : null
      const payload = { type: 'TO', value: toAccountHandler }
      this.$emit('accountChanged', payload)
    },
    toAccountProp() {
      this.checkToAccount()
    },
    accountNumbers() {
      const accountNumbersHandler =
        _.isArray(this.accountNumbers) && this.accountNumbers.length > 0
          ? this.accountNumbers
          : null
      this.$emit('accountsChanged', accountNumbersHandler)
    },
    accountNumbersProp() {
      this.checkAccountNumbers()
    }
  },
  created() {
    this.checkRequestType()
    this.checkFromAccount()
    this.checkToAccount()
    this.checkAccountNumbers()
  },
  methods: {
    checkRequestType() {
      if (!_.isEqual(this.requestTypeProp, this.requestType)) {
        this.requestType = this.requestTypeProp
      }
    },
    checkFromAccount() {
      if (!_.isEqual(this.fromAccountProp, this.fromAccount)) {
        this.fromAccount = this.fromAccountProp
      }
    },
    checkToAccount() {
      if (!_.isEqual(this.toAccountProp, this.toAccount)) {
        this.toAccount = this.toAccountProp
      }
    },
    checkAccountNumbers() {
      if (!_.isEqual(this.accountNumbersProp, this.accountNumbers)) {
        this.accountNumbers = _.cloneDeep(this.accountNumbersProp)
      }
    },
    tableIconClicked(attributeName) {
      this.focusAttribute = attributeName
      this.displaySelectAccountModal = true
    },
    selectAccountModalClosedHandler() {
      this.focusAttribute = null
      this.displaySelectAccountModal = false
    },
    selectAccountsModalClosedHandler() {
      this.displaySelectAccountsModal = false
    },
    accountSelectedHandler(selectedAccount) {
      const { accountNumber } = selectedAccount
      switch (this.focusAttribute) {
        case 'fromAccount': {
          this.fromAccount = accountNumber
          break
        }
        case 'toAccount': {
          this.toAccount = accountNumber
          break
        }
      }
    },
    accountsSelectedHandler(selectedAccounts) {
      if (selectedAccounts.length === 0) {
        this.accountNumbers = null
      } else {
        this.accountNumbers = selectedAccounts.map((el) => el.accountNumber)
      }
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12">
      <!-- select options -->
      <div class="d-flex mb-3">
        <div class="mr-5">
          <span>ประเภทของการเลือกบัญชี</span>
        </div>
        <b-form-radio
          v-model="requestType"
          :name="requestTypeOptions[0].value"
          :value="requestTypeOptions[0].value"
          class="mr-5"
        >
          {{ requestTypeOptions[0].text }}
        </b-form-radio>
        <b-form-radio
          v-model="requestType"
          :name="requestTypeOptions[1].value"
          :value="requestTypeOptions[1].value"
        >
          {{ requestTypeOptions[1].text }}
        </b-form-radio>
      </div>
    </b-col>

    <b-col cols="12">
      <!-- interval selection -->
      <div v-if="requestType === 'interval'" class="d-flex align-items-center">
        <span class="mr-2 text-nowrap">พิมพ์เลขบัญชี จาก</span>
        <b-input
          class="mr-2 input-field-fixed-width-size"
          placeholder="แรกสุด"
          v-model="fromAccount"
          :disabled="isGeneratingReportProp"
        ></b-input>
        <b-icon
          icon="table"
          class="cursor-pointer mr-2"
          @click="tableIconClicked('fromAccount')"
        ></b-icon>
        <span class="mr-2">ถึง</span>
        <b-input
          class="mr-2 input-field-fixed-width-size"
          placeholder="ท้ายสุด"
          v-model="toAccount"
          :disabled="isGeneratingReportProp"
        ></b-input>
        <b-icon
          icon="table"
          class="cursor-pointer"
          @click="tableIconClicked('toAccount')"
        ></b-icon>
      </div>

      <!-- specific selection -->
      <div v-if="requestType === 'specific'">
        <b-button
          variant="primary"
          class="px-3"
          size="sm"
          @click="displaySelectAccountsModal = true"
          :disabled="isGeneratingReportProp"
        >
          เลือกเฉพาะเลขบัญชี
        </b-button>
      </div>
    </b-col>

    <b-col
      v-if="
        requestType === 'specific' &&
        accountNumbers &&
        accountNumbers.length > 0
      "
      cols="12"
    >
      <div class="d-flex mt-3 flex-wrap">
        <div>
          <span>บัญชีที่ถูกเลือก: </span>
        </div>
        <div
          v-for="(accountNumber, index) in accountNumbers"
          :key="accountNumber"
          class="px-2"
        >
          <span>
            {{ accountNumber }}
            {{ index === accountNumbers.length - 1 ? '' : ',' }}
          </span>
        </div>
      </div>
    </b-col>

    <SelectAccountModal
      :displayModalProp="displaySelectAccountModal"
      :accountProp="{
        accountNumber:
          focusAttribute === 'fromAccount'
            ? fromAccount
            : toAccount ?? undefined
      }"
      @modalClosed="selectAccountModalClosedHandler"
      @accountSelected="accountSelectedHandler"
    ></SelectAccountModal>

    <SelectAccountsModal
      :accountSelectedIdsProp="accountNumbers ?? []"
      :displayModalProp="displaySelectAccountsModal"
      :summaryAccountAvailableProp="false"
      @modalClosed="selectAccountsModalClosedHandler"
      @accountsSelected="accountsSelectedHandler"
    ></SelectAccountsModal>
  </b-row>
</template>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
