<script>
import _ from 'lodash'

export default {
  props: {
    initialPageNumberProp: String,
    isGeneratingReportProp: Boolean
  },
  data() {
    return {
      initialPageNumber: null
    }
  },
  watch: {
    initialPageNumber() {
      const pageNumberHandler =
        _.isString(this.initialPageNumber) &&
        this.initialPageNumber.trim().length > 0
          ? this.initialPageNumber.trim()
          : null
      this.$emit('initialPageNumberChanged', pageNumberHandler)
    },
    initialPageNumberProp() {
      this.checkInitialPageNumber()
    }
  },
  created() {
    this.checkInitialPageNumber()
  },
  methods: {
    checkInitialPageNumber() {
      if (this.initialPageNumber !== this.initialPageNumberProp) {
        this.initialPageNumber = this.initialPageNumberProp
      }
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="5" class="d-flex align-items-center">
      <div>
        <span>พิมพ์หน้าเริ่มต้นจากหน้า</span>
      </div>
    </b-col>
    <b-col cols="7">
      <div>
        <b-form-input
          type="number"
          placeholder="1"
          v-model="initialPageNumber"
          :disabled="isGeneratingReportProp"
        ></b-form-input>
      </div>
    </b-col>
  </b-row>
</template>

<style></style>
